import { render, staticRenderFns } from "./myRegistration.vue?vue&type=template&id=23978fc4&scoped=true&"
import script from "./myRegistration.vue?vue&type=script&lang=js&"
export * from "./myRegistration.vue?vue&type=script&lang=js&"
import style0 from "./myRegistration.vue?vue&type=style&index=0&id=23978fc4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23978fc4",
  null
  
)

export default component.exports