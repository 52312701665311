// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/image/registerBg1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/image/registerBg2.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/image/registerBg3.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".box[data-v-23978fc4]{position:relative;width:23rem;background:#fff;margin:.5rem auto;padding:.5rem;min-height:23.2rem}.box .title[data-v-23978fc4]{font-size:1.2rem;padding-bottom:1rem;color:#333;font-weight:700;text-align:center;border-bottom:1px solid #d2d2d2}.box .ul[data-v-23978fc4]{position:relative;min-height:23.2rem}.box .ul li[data-v-23978fc4]{position:relative;float:left;width:23rem;height:7rem;margin-top:1rem;font-size:1rem;color:#333;border-radius:5px;font-weight:700}.box .ul li .type[data-v-23978fc4]{left:1.5rem}.box .ul li .status[data-v-23978fc4],.box .ul li .type[data-v-23978fc4]{position:absolute;top:1.5rem;font-size:1.2rem}.box .ul li .status[data-v-23978fc4]{right:1.5rem;color:#fff}.box .ul li .name[data-v-23978fc4]{position:absolute;bottom:1.5rem;left:1.5rem}.box .ul li .time[data-v-23978fc4]{position:absolute;right:1.5rem;bottom:1.5rem}.box .ul .li-Bg1[data-v-23978fc4]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.box .ul .li-Bg2[data-v-23978fc4]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100% 100%}.box .ul .li-Bg3[data-v-23978fc4]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:100% 100%}.box .noData[data-v-23978fc4]{position:absolute;left:50%;top:280px;font-size:28px;color:#333}.page-bar[data-v-23978fc4]{margin-bottom:0;margin-top:10px;margin-left:-.5rem}[data-v-23978fc4] .el-pagination .el-select .el-input{width:80px!important}[data-v-23978fc4] .el-pagination__jump{margin-left:0!important}", ""]);
// Exports
module.exports = exports;
